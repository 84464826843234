import { Box, IconButton, Link as MuiLink, Typography } from "@mui/material";
import CustomContainer from "../../CustomContainer";
import { FaFacebookF } from "react-icons/fa6";

import logo from '../../../assets/logo.png';
import { footer_content } from "../../../data/general_texts";
import { FaInstagramSquare } from "react-icons/fa";

const LogoAndDescription = () => (
    <Box sx={styles.logo_and_description}>
        <Box component={'img'} height={'5rem'} src={logo} />
        <Typography sx={styles.description} mt={'1rem'}>
            {footer_content.logo_and_description.description}
        </Typography>
    </Box>
);

const SitemapLinks = ({ links }) => (
    links.map((link, index) => (
        <MuiLink
            mb={'.5rem'}
            key={index}
            href={link.link}>
            {link.title}
        </MuiLink>
    ))
);

const SiteMap = () => {
    const links_list = footer_content.sitemap.nav_links;

    return (
        <Box sx={styles.sitemap}>
            <Typography fontFamily={'DM Serif Display'} fontSize={20} mb={'.5rem'}>
                {footer_content.sitemap.title}
            </Typography>
            <Box sx={styles.sitemap_links}>
                <SitemapLinks links={links_list} />
            </Box>
        </Box>
    );
};

const ContactInfo = () => (
    <Box sx={styles.contact_info}>
        <Typography fontFamily={'DM Serif Display'} fontSize={20} mb={'1rem'}>
            {footer_content.contact.title}
        </Typography>
        <MuiLink mb={'1rem'} href={footer_content.contact.call_phone} target='_blank' rel="noopener noreferrer">
            {footer_content.contact.phone}
        </MuiLink>
        <MuiLink mb={'1.5rem'} href={`mailto:${footer_content.contact.email_comercial}`} target='_blank' rel="noopener noreferrer">
            {footer_content.contact.email_comercial}
        </MuiLink>
        <Box display={'flex'}>
            <Box >
                <IconButton href="https://www.facebook.com/inovartgold/" target="_blank" sx={styles.contact_info_list}>
                    <FaFacebookF fontSize={16} />
                </IconButton>
            </Box>
            <Box >
                <IconButton href="https://www.instagram.com/inovartgold/" target="_blank" sx={styles.contact_info_list}>
                    <FaInstagramSquare fontSize={16} />
                </IconButton>
            </Box>
        </Box>
    </Box>
);

const FooterBanner = () => {
    const current_year = new Date().getFullYear();

    return (
        <Box sx={styles.footer_banner}>
            <Typography fontFamily={'DM Serif Display'} color="white" mr={'.5rem'}>
                {current_year}
                {' '}
                {footer_content.footer_banner.text}
            </Typography>
            <MuiLink target="_blank" href={footer_content.footer_banner.cta_link} color="secondary" rel="noopener noreferrer">
                {footer_content.footer_banner.cta}
            </MuiLink>
        </Box>
    );
};

export default function Footer() {
    return (
        <Box component={'footer'}>
            <CustomContainer>
                <Box sx={styles.content_container}>
                    <LogoAndDescription />
                    <SiteMap />
                    <ContactInfo />
                </Box>
            </CustomContainer>
            <FooterBanner />
        </Box>
    );
};

const styles = {
    content_container: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '3.5rem',
        pb: '5rem',
    },
    logo_and_description: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: {
            xs: 'center',
            md: 'start'
        },
        flexDirection: 'column'
    },
    description: {
        textAlign: {
            xs: 'center',
            md: 'left'
        },
        whiteSpace: 'pre-wrap'
    },
    sitemap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: {
            xs: 'center',
            md: 'start'
        },
        mt: {
            xs: '2.5rem',
            md: '0rem'
        },
    },
    sitemap_links: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            md: 'start'
        }
    },
    contact_info: {
        display: 'flex',
        flexDirection: 'column',
        mt: {
            xs: '2.5rem',
            md: '0rem'
        },
        alignItems: {
            xs: 'center',
            md: 'start'
        }
    },
    contact_info_list: {
        border: theme => `3px solid ${theme.palette.secondary.main}`,
        mr: '.875rem',
        color: theme => theme.palette.primary.main
    },
    footer_banner: {
        bgcolor: 'black',
        display: 'flex',
        justifyContent: 'center',
        py: '.5rem'
    }
};
