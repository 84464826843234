
import { createTheme } from '@mui/material';

let theme = createTheme({
    palette: {
        primary: {
            main: '#010101',
        },
        secondary: {
            main: '#C8A459',
        },
        text: {
            primary: '#010101',
        },
    },
    shape: {
        borderRadius: 8,
    },
    shadows: Array(25).fill('0px 18px 18px rgba(0, 0, 0, 0.15)'),
});

theme = createTheme(theme, {
    typography: {
        h1: {
            fontFamily: 'DM Serif Display',
            fontWeight: 400,
            color: 'white',
            fontSize: '2rem',
            [theme.breakpoints.up('lg')]: {
                fontSize: '4rem',
            },
        },
        h2: {
            fontFamily: 'DM Serif Display',
            fontWeight: 400,
            fontSize: '1.875rem',
            [theme.breakpoints.up('lg')]: {
                fontSize: '2.5rem',
            },
        },
        h3: {
            fontFamily: 'DM Serif Display',
            fontWeight: 400,
            fontSize: '1.875rem',
            [theme.breakpoints.up('lg')]: {
                fontSize: '2.5rem',
            },
        },
        subtitle1: {
            fontFamily: 'DM Serif Display',
            fontSize: '1rem',
            color: 'white',
            fontWeight: 400,
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.25rem',
            },
        },
        subtitle2: {
            fontFamily: 'Montserrat',
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        body1: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            textAlign: 'center',
        },
        button: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1rem',
            textTransform: 'none',
            textAlign: 'center'
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: 'Montserrat',
                    textDecoration: 'none',
                    transition: '.3s',
                    '&:hover': {
                        color: theme.palette.secondary.main,
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '.5rem 3.5rem',
                    fontWeight: '600',
                    color: 'white',
                    [theme.breakpoints.up('lg')]: {
                        padding: '.5rem 5rem',
                    },
                },
            },
        },
    },
});

export default theme;