import { Box } from "@mui/material";
import { lazy, Suspense } from "react";

import Hero from "./Components/Hero";

const Categories = lazy(() => import("./Components/Categories"));
const FirstCTA = lazy(() => import("./Components/FirstCTA"));
const Gallery = lazy(() => import("./Components/Gallery"));
const Advantages = lazy(() => import("./Components/Advantages"));
const AboutUs = lazy(() => import("./Components/AboutUs"));
const LastCTA = lazy(() => import("./Components/LastCTA"));

export default function Home() {
  return (
    <Box component="main">
      <Hero />
      <Suspense fallback={<div>Carregando</div>}>
        <Categories />
        <FirstCTA />
        <Gallery />
        <Advantages />
        <AboutUs />
        <LastCTA />
      </Suspense>
    </Box>
  );
};