import { Box } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout({ children }) {
    return (
        <>
            <Header />
            <Box sx={styles.contentBox}>
                {children}
            </Box>
            <Footer />
        </>
    );
};

const styles = {
    contentBox: {
        overflow: 'hidden',
        pt: '7rem',
    }
};
