export const general_links = {
    whatsapp: '51 9 9999-8888',
    telefone: '(51) 3093-6878',
    whatsapp_link: 'https://wa.me/5551995760457?text=Olá! Estou vindo do site e gostaria de conhecer a Inovart Gold!',
    email_comercial: 'comercial@inovartgold.com.br',
    developer_name: 'Spítha',
    developer_website: 'https://spitha.com.br',
    whatsapp_links:{
        whatsapp: 'https://api.whatsapp.com/send?phone=551999988888',
    }
}