import { Box, Button, Typography } from "@mui/material";
import CustomContainer from "../../../../components/CustomContainer";
import { StyledSection } from "../../../../components/Section";

import hero_bg from '../../../../assets/hero_bg.jpg';
import hero_video from '../../../../assets/hero_video.mp4'; // Importa o vídeo
import { hero_content } from "../../../../data/general_texts";

export default function Hero() {
    return (
        <StyledSection sx={styles.section}>
            <CustomContainer>
                <Box id={'hero'} sx={styles.content}>
                    <Typography variant="subtitle1">
                        {hero_content.subtitle}
                    </Typography>
                    <Typography variant="h1" sx={styles.title}>
                        {hero_content.title}
                    </Typography>
                    <Button
                        target="_blank"
                        href={hero_content.cta_link}
                        variant="contained"
                        color="secondary"
                        aria-label="Call to action button"
                    >
                        {hero_content.cta}
                    </Button>
                </Box>
            </CustomContainer>

            {/* Exibe a imagem no mobile */}
            <Box
                component={'img'}
                src={hero_bg}
                sx={{
                    ...styles.background,
                    display: { xs: 'block', md: 'none' }, // Exibe imagem em telas menores que md
                }}
            />

            {/* Exibe o vídeo no desktop com gradiente escuro */}
            <Box sx={styles.videoContainer}>
                <Box
                    component={'video'}
                    src={hero_video}
                    autoPlay
                    muted
                    loop
                    sx={styles.video}
                />
                <Box sx={styles.gradientOverlay} />
            </Box>
        </StyledSection>
    );
};

const styles = {
    section: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        overflow: 'hidden',
    },
    content: {
        py: { xs: '8rem', md: '11rem' }, // Usa valores de espaçamento responsivo
        zIndex: 1, // Garante que o conteúdo fique acima do vídeo e do gradiente
    },
    title: {
        whiteSpace: 'pre-wrap',
        mt: '1rem',
        mb: '3rem',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -1,
    },
    videoContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: { xs: 'none', md: 'block' }, // Exibe vídeo em telas md ou maiores
        zIndex: -1, // Coloca o vídeo atrás do conteúdo
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    gradientOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.7) 100%)',
        zIndex: 0, // Camada acima do vídeo, mas abaixo do conteúdo
    },
};
