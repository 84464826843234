import { StyledSection } from "../../components/Section";
import { LoginForm } from "./Components/LoginForm";

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'grey',
        minHeight: '90vh'
    }
}

export default function Login() {

    return (
        <StyledSection sx={styles.section}>
            <LoginForm />
        </StyledSection>
    );
};