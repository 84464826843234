import { Box, Button, Card, Checkbox, TextField, Typography } from "@mui/material";

import logo from '../../../../assets/logo.png';
import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const LoginForm = () => {

    return (
        <Card sx={{
            my: '5rem',
            mx: '1rem',
            p: '2rem'
        }}>
            <Box component={'img'} src={logo} mb={'1rem'} />
            <Box>
                <TextField fullWidth sx={{
                    mb: '1rem',
                }}
                label="Usuário*"
                />
                <TextField fullWidth sx={{
                    mb: '1rem',
                }}
                label="Senha*"
                type="password"
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent:'center',
                    alignItems: 'center',
                    mb:'1rem'
                }}>
                    <Checkbox />
                    <Typography>
                        Concordo com os Termos de Uso e Políticas de Privacidade
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Button startIcon={<FaChevronRight size={15} />} variant="contained" color="secondary">
                        Login
                    </Button>

                    <Link>
                        Esqueceu a senha?
                    </Link>
                </Box>
            </Box>
        </Card>
    )
}