import { general_links } from "./general_links";

const nav_links = [
    {
        title: "Início",
        link: "#hero"
    },
    {
        title: "Joias",
        link: "#joias"
    },
    {
        title: "Nossos Destaques",
        link: "#destaques"
    },
    {
        title: "Vantagens",
        link: "#vantagens"
    },
    {
        title: "Sobre nós",
        link: "#sobre"
    },
    
];

export const header_content = {
    promotion_banner_content: {
        text: "Seja um parceiro Inovart Gold.",
        cta: "Converse com um de nossos especialistas",
        cta_link: general_links.whatsapp_link
    },
    main_links: nav_links,
    auth_content: {
        text: "Já é cliente? Faça seu login"
    }
};

export const hero_content = {
    subtitle: 'Inovart Gold Fábrica de Alianças',
    title: `Referência em fabricação
    de joias desde 1980`,
    cta: 'Me tornar um parceiro Inovart Gold',
    cta_link: general_links.whatsapp_link
};

export const categories_content = {
    subtitle: 'Estilos de Joias',
    title: 'Sob medida para seus clientes',
    description: `Confie sua demanda de joias em Ouro 18K, 10K e prata a um fornecedor 
    com prazos flexíveis e peças customizadas para seus clientes.`
};

export const first_cta_content = {
    title: 'Detalhes que fazem diferença',
    description: 'Temos como compromisso trazer a visão de seu cliente para a produção, construindo a joia que melhor se encaixa em suas expectativas.',
    cta: 'Conheça nossas joias',
    cta_link: general_links.whatsapp_link
};

export const gallery_content = {
    subtitle: 'Nossa Galeria',
    title: 'Mais de 3 mil modelos de joias',
    description: 'Encontre entre nossas criações a joia que mais corresponde ao desejo do seu cliente ou faça um novo projeto conosco.',
    cta: 'Conheça nossas joias',
    cta_link: general_links.whatsapp_link
};

export const advantages_content = {
    subtitle: 'Por que escolher a Inovart',
    title: 'Um Parceiro e Fornecedor',
    description: 'Colaboramos com seu negócio para entregar agilidade para você e qualidade para os seus clientes.'
};

export const about_us_content = {
    subtitle: 'Nossa História',
    title: `Muito mais que
“A Fábrica das Alianças”`,
    description: `Por anos conhecida no coração de Porto Alegre como “A Fábrica das Alianças”, hoje a Inovart Gold se destaca principalmente pela variedade na produção de joias customizadas.
                           
Inaugurada em 1980, acumula em catálogo mais de 3.000 modelos de alianças e joias personalizadas.`,
    second_title: `Há mais de 40 anos no ramo`,
    second_description: `Procuramos junto de nossos clientes a constante expansão de possibilidades para atender as mais diversas demandas.
    
Atualmente, através do investimento em tecnologias de ponta, nosso maior diferencial se tornou a confecção rápida e de alta qualidade dos mais diversos modelos.`
};

export const last_cta_content = {
    title: 'Joias feitas por mãos experientes',
    description: 'Há 40 anos entregando resultados para empresas do ramo joalheiro do Rio Grande do Sul e Brasil.',
    cta: 'Entre em contato',
    cta_link: general_links.whatsapp_link
};

export const footer_content = {
    logo_and_description: {
        description: `Fundada em 1980, Inovart Gold é 
uma fábrica de joias especializada em
alianças e joias customizadas.`
    },
    sitemap: {
        title: 'Mapa',
        nav_links: nav_links
    },
    contact: {
        phone: '(51) 3093-6878',
        call_phone: 'tel:555130936878',
        title: 'Nos contate',
        email_comercial: general_links.email_comercial,
    },
    footer_banner: {
        text: 'Desenvolvido por ',
        cta: 'Spitha',
        cta_link: 'https://www.spitha.com.br',
    }
}
