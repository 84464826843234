import { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Link as MuiLink, Typography } from "@mui/material";
import { FaAngleRight, FaBars, FaRegCircleUser, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

import logo from '../../../assets/logo.png';
import { header_content } from "../../../data/general_texts";

const links_list = header_content.main_links;

const PromotionBanner = ({ isMobile }) => {

    if (isMobile) {
        return (
            <Box sx={styles.promotion_mobile}>
                <MuiLink color="secondary" href={header_content.promotion_banner_content.cta_link} target="_blank" textAlign={'center'} sx={styles.cta} >
                    {header_content.promotion_banner_content.cta} <Box component={FaAngleRight} ml={'.5rem'} />
                </MuiLink>
            </Box>
        );
    } else {
        return (
            <Box sx={styles.promotion}>
                <Typography color="white" mr={'.25rem'}>
                    {header_content.promotion_banner_content.text}
                </Typography>
                <MuiLink color="secondary" href={header_content.promotion_banner_content.cta_link} target="_blank" textAlign={'center'} sx={styles.cta} >
                    {header_content.promotion_banner_content.cta} <Box component={FaAngleRight} ml={'.5rem'} />
                </MuiLink>
            </Box>
        );
    }
};

const NavBar = () => {

    return (
        <Box sx={styles.list_link}>
            {
                links_list.map((link, index) => (
                    <MuiLink
                        key={index}
                        href={link.link}
                        sx={styles.link}>
                        {link.title}
                    </MuiLink>
                ))
            }
        </Box>
    );
};

const MainLinksDesktop = () => {

    return (
        <Box sx={styles.main_links}>
            <ImageNavigation />
            <NavBar />
            {/* <MuiLink disabled sx={styles.login} component={Link} to={'/login'} textAlign={'center'}>
                {header_content.auth_content.text} <Box component={FaRegCircleUser} ml={'.5rem'} />
            </MuiLink> */}
            <Typography color='grey'>
                {header_content.auth_content.text} <Box component={FaRegCircleUser} ml={'.5rem'} />
            </Typography>
        </Box>
    );
};

const ImageNavigation = () => {

    return (
        <Link to={'#hero'}>
            <Box sx={{
                borderRadius: '2rem'
            }} component={'img'} height={'5rem'} src={logo} />
        </Link>
    );
};


export default function Header() {

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const CustomDrawer = () => {

        return (
            <Drawer
                anchor={'top'}
                open={open}
                onClose={toggleDrawer(false)}
            >
                <List onClick={toggleDrawer(false)}>
                    {links_list.map((link, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton href={link.link}>
                                <ListItemText primary={link.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        );
    };

    const MainLinksMobile = () => {

        return (
            <Box sx={styles.main_links_mobile}>
                <MuiLink onClick={toggleDrawer(true)}>
                    <FaBars color="white" size={28} />
                </MuiLink>
                <ImageNavigation />
                <Box>
                    {/* <MuiLink component={Link} to={'/login'} mr={'1rem'}><FaRegCircleUser size={28} /></MuiLink> */}
                    <MuiLink target="_blank" href={header_content.promotion_banner_content.cta_link}><FaWhatsapp color="white" size={30} /></MuiLink>
                </Box>
            </Box>
        );
    };

    return (
        <Box component={'header'} sx={styles.header}>
            <CustomDrawer />
            <PromotionBanner />
            <PromotionBanner isMobile />
            <MainLinksDesktop />
            <MainLinksMobile />
        </Box>
    );
};

const styles = {
    header: {
        position: 'fixed',
        width: '100vw',
        zIndex: 1200,
        bgcolor: '#231F20'
    },
    promotion: {
        bgcolor: '#010101',
        display: {
            xs: 'none',
            md: 'flex'
        },
        justifyContent: 'center',
        alignItems: 'center',
        p: '.5rem',
    },
    promotion_mobile: {
        bgcolor: '#010101',
        display: {
            xs: 'flex',
            md: 'none'
        },
        justifyContent: 'center',
        alignItems: 'center',
        p: '.5rem',
    },
    list_link: {
        display: {
            xs: 'none',
            lg: 'flex'
        },
        justifyContent: 'center',
        alignItems: 'center',
        py: '1.5rem'
    },
    link: {
        color: 'white',
        ':not(:last-child)': {
            mr: '2.5rem'
        }
    },
    main_links: {
        display: {
            xs: 'none',
            md: 'flex'
        },
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    main_links_mobile: {
        display: {
            xs: 'flex',
            md: 'none'
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '1rem',
        py: '.25rem',
    },
    login: {
        display: 'flex',
        alignItems: 'center'
    },
    cta: {
        display: 'flex',
        alignItems: 'center',
        fontSize: {
            xs: '.875rem',
            md: '1rem'
        }
    }
};